.carsearcher-mobile {
  display: none;
}

.subsection--content.mainMenu {
  grid-template-rows: auto;
}

.carsearcher-content {
  height: 100px;
  overflow-y: scroll;
}

.banner-find {
  -webkit-appearance: none;
  text-align: left;
  text-indent: 35px;
  cursor: pointer;
  background: url("icon-search.3fdf0df2.svg") 10px / 15px no-repeat;
  border: .1px solid #a4a8a8;
  border-radius: 25px;
  width: 290px;
  height: 40px;
}

.tgsearch {
  background: url("telegram.65610c8a.png") 25px / 25px no-repeat;
}

.banner-subsearcher {
  color: #1937b9;
}

.searcherGrid {
  display: block;
}

.contHeigh {
  max-height: 512px;
  padding: 20px 0;
}

.banner {
  max-width: 100%;
  font-size: 16px;
  overflow-y: scroll;
}

.banner--payment {
  border-top: .5px solid;
  margin: 30px 50px 0;
  padding-top: 20px;
}

.banner-search.banner.banner-back {
  text-align: center;
  text-indent: 0;
  color: #1937b9;
  cursor: pointer;
  border-color: #1937b9;
  margin: 5px;
}

.banner-search {
  -webkit-appearance: none;
  text-indent: 70px;
  text-align: left;
  cursor: pointer;
  border: .1px solid #a4a8a8;
  border-radius: 25px;
  margin: 5px;
  padding: 10px;
}

.banner-search.banner.searcherGrid span {
  max-width: 150px;
  margin-left: auto;
  margin-right: auto;
}

/*# sourceMappingURL=style-bannerSearch.b24219cc.css.map */
